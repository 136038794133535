import React, {useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import NumberFormat from "react-number-format";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import {Navigation} from "../Navigation";
import {TopBar} from "../TopBar";
import {Link} from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {CSVLink} from "react-csv";
import {Footer} from "../Footer";

export function PaginatePaymentLinkList(props) {

    const zeroPad = (num, places) => String(num).padStart(places, '0')
    const [supplierData, setSupplierData] = useState(props.supplier_data);
    const [modalShows, setModalShows] = React.useState(false);


    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * props.perPageLink;
        props.setCurrentPageLink(selectedPage);
        props.setOffsetLink(offset);

    };

    const exportPDF = (e)=>{

    }

    return (
        <>
            <div className="col-12">
                <div className='rg-container'>
                    <table className="transactions-table" summary="Hed">
                        <thead>
                        {sessionStorage.getItem("school")==1?(
                        <tr>
                            <th className="">Student Name</th>
                            <th className="">Admission Number</th>
                            <th className="">Class</th>
                            <th className="">Payment Reference</th>
                            <th className="">Total Payment</th>
                            <th className="">Customer Name</th>
                            <th className="">Customer Phone Number</th>
                            <th className="">Date of Transaction</th>
                          {/*  <th className="">Transaction Status</th>*/}



                        </tr>
                        ):(
                            <tr>
                                <th className="">Payment Link Description</th>
                                <th className="">Total Payment</th>

                                <th className="">Customer Name</th>
                                <th className="">Customer Phone Number</th>

                                <th className="">Date of Transaction</th>
                                <th className="">Transaction Status</th>

                                <th className="">Created By</th>
                                <th className="">Process Completed</th>

                            </tr>
                        )}
                        </thead>
                        <tbody>
                        {props.linkDataRender}
                        </tbody>
                    </table>
                    {props.linkDataRender.length > 0 ? (
                        <ReactPaginate
                            previousLabel={"PREV"}
                            nextLabel={"NEXT"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={props.pageCountLink}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                    ) : ('')}
                </div>

            </div>

        </>
    );
}
